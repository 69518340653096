/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// https://medium.com/frontend-digest/a-technical-guide-to-seo-with-gatsby-js-e88a7dac80f0

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ title, description, image, slug, schema }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            image
            social {
              twitter
            }
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata?.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image || site.siteMetadata?.image;
  const metaSlug = `${site.siteMetadata.siteUrl}/${slug || ""}`;

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      {/* Default stuff */}
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaImage} />
      {/* <link rel="canonical" href={url} /> */}

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metaSlug} />
      <meta
        property="twitter:creator"
        content={site.siteMetadata.social.twitter}
      />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaImage} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" ontent={metaSlug} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />

      {schema ? (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      ) : null}
    </Helmet>
  );
}

export default SEO;
